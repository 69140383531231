import React from 'react';
import Layout from '../layouts/en';
import Index from '../components/pages/index';

const IndexPage = props => (
  <Layout {...props}>
    <Index {...props} />
  </Layout>
);

export default IndexPage;
